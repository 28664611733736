import * as React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import Translate from "../../components/translation";
import Seo from "../../components/seo";
import Layout from "../../components/layout";
import * as style from "../works/index.module.scss";

const CSS_Arts = ({ data }) => {
  console.log(data);
  return (
    <Layout pageTitle={<Translate>Art Board</Translate>}>
      <Seo
        title="Art Board"
        description="Art Collection made with CSS and codes"
      />
      <div className={style.container}>
        <ul className={style.worksList}>
          <li>
            <Link to="/css_art/pikachu">
              <div className={style.thumb}>
                <StaticImage src="./pikachu/thumb.png" />
              </div>
              <p>Pikachu</p>
            </Link>
          </li>

          <li>
            <Link to="/css_art/gengar-halloween">
              <div className={style.thumb}>
                <StaticImage src="./gengar-halloween/thumb.png" />
              </div>
              <p>Gengar Halloween</p>
            </Link>
          </li>
        </ul>
      </div>
    </Layout>
  );
};

export default CSS_Arts;

export const query = graphql`
  query ($locale: String) {
    allSitePage(
      filter: {
        path: { regex: "/(css_art/){1}.+/" }
        context: { locale: { eq: $locale } }
      }
    ) {
      nodes {
        id
        path
      }
    }
  }
`;
